import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { PendingItemsData } from '../../pendging/pending-transfers.types';

import { getPendingTransfers } from '../../api';

export const useAllPendingTransfers = () => {
  const [data, setData] = useState<PendingItemsData>(RD.initial);

  const load = useCallback(() => {
    setData(RD.pending);

    getPendingTransfers.getAllPendingTransfers().then(setData);
  }, []);

  return [data, load] as const;
};
