export const MinimizeGraph = () => (
  <svg width="207" height="36" viewBox="0 0 207 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M16.7005 25.5237L8.32689 26.432L1 24.6155V27.8853C1 32.099 4.41589 35.5149 8.62961 35.5149H195.464C201.484 35.5149 206.364 30.635 206.364 24.6154V2.81657L202.49 2.36243H198.303L193.069 1L186.789 2.36243H181.556L177.369 7.35801L168.472 5.08729L163.238 8.72044L151.201 10.537L141.258 8.72044L132.884 17.3492L130.791 13.716L127.651 17.3492L123.464 14.6243L119.277 17.3492L114.567 14.6243L106.717 17.3492L93.6329 14.6243L80.5491 18.2574L75.3156 17.3492L70.6055 19.6199L63.8019 23.7072L50.7182 21.4365L39.7279 22.7989L31.3543 24.6155L24.0274 22.7989L16.7005 25.5237Z"
      fill="url(#paint0_linear_6485_1450)"
    />
    <path
      // eslint-disable-next-line max-len
      d="M206.364 2.36243H202.49H198.303L193.069 1L185.219 2.36243H181.032L177.369 7.35801L168.472 5.54143L163.238 8.72044L151.201 10.537L141.258 8.72044L132.884 17.3492L131.314 13.716L127.651 17.3492L123.464 14.6243L119.277 17.3492L114.567 14.6243L106.717 17.3492L93.1095 14.1702L80.5491 18.2574L75.3156 17.3492L70.6055 19.6199L63.8019 23.7072L50.7182 21.4365L39.7279 22.3447L31.8776 25.0696L25.0741 22.3447L17.7472 25.0696H9.37359L1 23.253"
      stroke="var(--image-color, #5FA776)"
      strokeWidth="0.908287"
    />
    <rect x="1" y="10.9911" width="56.5217" height="23.6155" rx="6.35801" fill="url(#paint1_linear_6485_1450)" />
    <defs>
      <linearGradient
        id="paint0_linear_6485_1450"
        x1="83.0918"
        y1="11.6385"
        x2="83.225"
        y2="44.1474"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--image-color, #5FA776)" stopOpacity="0.37" />
        <stop offset="1" stopColor="white" stopOpacity="0.13" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6485_1450"
        x1="54.905"
        y1="25.0948"
        x2="1.18231"
        y2="28.5695"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--shade-color, white)" stopOpacity="0" />
        <stop offset="1" stopColor="var(--shade-color, white)" />
      </linearGradient>
    </defs>
  </svg>
);
