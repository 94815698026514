import { useCallback, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { ApiError } from '@models/ApiError';

import { getAllBankAccounts } from '../api';
import { BankAccount } from '../types';

export const useAllBankAccountsHook = () => {
  const [state, setState] = useState<RD.RemoteData<ApiError, BankAccount[]>>(RD.initial);

  const load = useCallback(() => {
    setState(RD.pending);
    getAllBankAccounts().then(setState);
  }, []);

  return [state, load] as const;
};
