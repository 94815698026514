import { MinimizeGraph } from './images/minimize-graph';
import { BalancedGraph } from './images/balanced-greaph';
import { MaximizeGraph } from './images/maximize-graph';

export enum Reason {
  MORE_OUT_OF_SAVINGS = 'MORE_OUT_OF_SAVINGS',
  BUILD_EXTRA_INCOME = 'BUILD_EXTRA_INCOME',
  SAVE_EXTRA_FOR_UNIVERSITY = 'SAVE_EXTRA_FOR_UNIVERSITY',
  SAVE_EXTRA_FOR_EMERGENCY = 'SAVE_EXTRA_FOR_EMERGENCY',
  DONT_KNOW = 'DONT_KNOW',
}

export const reasonDisplayMap = {
  [Reason.MORE_OUT_OF_SAVINGS]: 'Get more out of my savings',
  [Reason.BUILD_EXTRA_INCOME]: 'Build extra income for retirement',
  [Reason.SAVE_EXTRA_FOR_UNIVERSITY]: 'Save extra for college',
  [Reason.SAVE_EXTRA_FOR_EMERGENCY]: 'Save extra for an emergency',
  [Reason.DONT_KNOW]: "I don't know yet",
};

export const getReasonDisplay = (reason: Reason) => reasonDisplayMap[reason];

export enum ControlLevel {
  FULL = 'FULL',
  NO_CONTROL = 'NO_CONTROL',
  SEMI_CONTROL = 'SEMI_CONTROL',
}

export const controlLevelValues = [
  {
    value: ControlLevel.NO_CONTROL,
    title: 'Hands-off',
    descriptions: 'I want my investments to be on auto pilot',
  },
  {
    value: ControlLevel.SEMI_CONTROL,
    title: 'Some control',
    descriptions: 'I want expert guidance but also flexibility to adjust',
  },
  {
    value: ControlLevel.FULL,
    title: 'Full control',
    descriptions: 'I want to pick and manage my own investments',
  },
];

export enum Steps {
  PREFERENCES = 'PREFERENCES',
  PORTFOLIO_TYPE_SELECTION = 'PORTFOLIO_TYPE_SELECTION',
  PREMADE_PORTFOLIO_SELECTION = 'PREMADE_PORTFOLIO_SELECTION',
  RISKS = 'RISKS',
  TOPICS = 'TOPICS',
  // User already has portfolios
  ONBOARDED_PORTFOLIO_TYPE_SELECTION = 'ONBOARDED_PORTFOLIO_TYPE_SELECTION',
  ONBOARDED_PREFERENCES = 'ONBOARDED_PREFERENCES',
  ONBOARDED_RISKS = 'ONBOARDED_RISKS',
  ONBOARDED_PREMADE_PORTFOLIO_SELECTION = 'ONBOARDED_PREMADE_PORTFOLIO_SELECTION',
  ONBOARDED_TOPICS = 'ONBOARDED_TOPICS',
}

export const appetitePointsMap = {
  minimize: 2,
  balance: 4,
  maximize: 6,
};

export const appetitePointsValues = [
  {
    value: 'minimize',
    title: 'Minimize losses',
    descriptions: 'I’m okay with lower returns',
    image: <MinimizeGraph />,
  },
  {
    value: 'balance',
    title: 'Balance risk',
    descriptions: 'I’m okay with moderate returns',
    image: <BalancedGraph />,
  },
  {
    value: 'maximize',
    title: 'Maximize returns',
    descriptions: 'I’m okay with higher risk',
    image: <MaximizeGraph />,
  },
];

export type Appetite = keyof typeof appetitePointsMap;

export type Step = keyof typeof Steps;
