import { ACHRelationship } from '@models/transfers';
import { getLastDigits } from '@utils/getLastDigits.helper';
import { mapNumberDto } from '~/shared/dtoMappers';

import { BankAccount } from '../types';
import { mapAccountType } from '../helpers';

export const mapAchAccountToBankAccount = (ach: ACHRelationship): BankAccount => {
  let balance: number | undefined = undefined;
  if (ach.balance === undefined || ach.balance === null) {
    balance = undefined;
  } else if (ach.balance === 0) {
    balance = 0;
  } else {
    balance = mapNumberDto(ach.balance);
  }
  return {
    id: ach.id,
    displayName: ach.displayName || ach.type,
    type: mapAccountType(ach.type),
    lastDigits: getLastDigits(ach.lastDigits),
    // todo recheck for portfolioId value
    portfolioId: '',
    // createdAt: mapIsoDate(ach.created_at),
    balance,
    connectionType: ach.connectionType,
  };
};
