export const BalancedGraph = () => (
  <svg width="206" height="57" viewBox="0 0 206 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M18.2938 37.7856L8.94824 36.8773L0.641113 39.148V56.4055H205.204V1L199.493 4.63315L195.339 7.35801H186.513L179.244 13.716L173.533 16.4409L170.418 20.5282L166.264 16.4409L162.111 19.6199L151.727 12.3536L139.266 26.432H131.478L128.363 18.7116L126.286 26.432L121.614 21.4365L117.979 26.432L113.307 23.7072L105.519 26.432L93.0579 28.2486L78.0012 34.1525L73.3285 30.5193L68.6557 31.4276L63.983 34.1525L49.4455 32.79L37.504 35.969L29.1969 37.7856L25.5625 35.969L18.2938 37.7856Z"
      fill="url(#paint0_linear_6485_1283)"
    />
    <path
      // eslint-disable-next-line max-len
      d="M204.685 1L197.935 5.54143L195.339 7.35801H186.513L179.244 13.716L173.533 16.4409L170.418 20.5282L166.264 16.4409L162.111 19.1657L151.727 12.3536L139.266 26.432H131.478L128.363 18.7116L126.286 26.432L121.614 21.4364L117.979 26.432L113.307 23.7072L105.519 26.432L93.0579 28.2486L78.0013 34.1525L73.3285 30.0652L68.6557 31.4276L63.983 34.1525L49.4455 32.79L39.0616 35.5149L29.1969 37.7856L25.5625 35.969L18.2938 37.7856L11.0251 36.8773L2.19873 39.148"
      stroke="var(--image-color, #5FA776)"
      strokeWidth="0.908287"
    />
    <rect x="0.641113" y="19.6199" width="56.0731" height="32.6983" rx="6.35801" fill="url(#paint1_linear_6485_1283)" />
    <defs>
      <linearGradient
        id="paint0_linear_6485_1283"
        x1="82.0813"
        y1="20.7214"
        x2="82.2156"
        y2="53.2302"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--image-color, #5FA776)" stopOpacity="0.37" />
        <stop offset="1" stopColor="white" stopOpacity="0.13" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6485_1283"
        x1="54.1182"
        y1="39.148"
        x2="0.713709"
        y2="41.6229"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--shade-color, white)" stopOpacity="0" />
        <stop offset="1" stopColor="var(--shade-color, white)" />
      </linearGradient>
    </defs>
  </svg>
);
