import React, { FC, useCallback, useState } from 'react';

import { Button } from '~/ui/kit/atoms/Button';

import { BankAccount } from '../../types';
import { DeleteACHConfirmationDialog } from './DeleteConfirmationDialog';
import * as Styled from './PlainAccount.styled';

type Props = BankAccount & {
  isRemovable: boolean;
  onRemove: (bankAccount: BankAccount) => void;
  className?: string;
  connectedAccountId?: string;
};

export const PlainAccount: FC<Props> = ({ onRemove, connectedAccountId = '', ...props }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const isConnectedAccount = connectedAccountId.endsWith(props.lastDigits) || connectedAccountId === props.lastDigits;

  const closeConfirmation = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const openConfirmation = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  const handleSuccess = useCallback(() => {
    onRemove({
      id: props.id,
      displayName: props.displayName,
      type: props.type,
      lastDigits: props.lastDigits,
      portfolioId: props.portfolioId,
      balance: props.balance,
      connectionType: props.connectionType,
    });
  }, [
    onRemove,
    props.id,
    props.displayName,
    props.type,
    props.lastDigits,
    props.portfolioId,
    props.balance,
    props.connectionType,
  ]);

  return (
    <Styled.Container className={props.className}>
      <DeleteACHConfirmationDialog visible={showConfirmation} onCancel={closeConfirmation} onAccept={handleSuccess} />
      <Styled.Info
        label={`${props.displayName || props.type}`}
        accountNumber={props.lastDigits}
        accountType={`${props.connectionType !== 'platform' ? 'External' : ''} Account`}
        balance={props.balance}
        isConnected={isConnectedAccount}
      />
      {props.isRemovable && (
        <Button type="ghost" onClick={openConfirmation}>
          <Styled.Icon />
        </Button>
      )}
    </Styled.Container>
  );
};
