export const MaximizeGraph = () => (
  <svg width="207" height="85" viewBox="0 0 207 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M18.5942 56.4055L9.17389 55.4972L0.800293 57.7679V84.5624H195.192C201.714 84.5624 207 79.2759 207 72.7546V1H206.477L199.673 20.5282L196.01 19.6199L187.546 18.2574L180.833 26.8862L175.599 32.3359L171.936 39.148L166.702 27.7945L163.562 38.2398L154.665 21.4364L141.058 38.2398L136.348 50.0475L129.544 32.3359L126.404 42.327L122.741 34.6066L118.031 42.327L113.844 38.2398L106.517 45.0519L98.6666 68.6674L90.8164 50.0475L74.0692 49.1392L69.3591 50.0475L64.6489 52.7723L49.9951 51.4099L37.9581 54.5889L29.5845 56.4055L25.9211 54.5889L18.5942 56.4055Z"
      fill="url(#paint0_linear_6485_1380)"
    />
    <path
      // eslint-disable-next-line max-len
      d="M2.37061 57.7679L11.2675 55.4972L18.5944 56.4055L25.9213 54.5889L29.5848 56.4055L39.5284 54.1348L49.9954 51.4099L64.6492 52.7723L69.3593 50.0475L74.0695 48.6851L90.2933 50.0475L98.6669 68.6674L106.517 45.0519L113.844 38.6939L118.031 42.327L122.741 34.6066L126.404 42.327L130.068 32.3359L136.348 50.5016L141.058 38.6939L154.665 21.4365L163.562 37.7856L166.702 28.2486L171.936 39.148L175.599 32.3359L181.356 26.8862L187.546 18.7116L196.01 19.6199L199.673 20.5282L205.954 1H207"
      stroke="var(--image-color, #5FA776)"
      strokeWidth="0.908287"
    />
    <rect x="1.80127" y="47.0502" width="55.3147" height="33.0616" rx="6.35801" fill="url(#paint1_linear_6485_1380)" />
    <defs>
      <linearGradient
        id="paint0_linear_6485_1380"
        x1="82.8921"
        y1="39.3413"
        x2="83.0253"
        y2="71.8501"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--image-color, #5FA776)" stopOpacity="0.37" />
        <stop offset="1" stopColor="white" stopOpacity="0.13" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_6485_1380"
        x1="54.5551"
        y1="66.7953"
        x2="1.86745"
        y2="69.1774"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="var(--shade-color, white)" stopOpacity="0" />
        <stop offset="1" stopColor="var(--shade-color, white)" />
      </linearGradient>
    </defs>
  </svg>
);
